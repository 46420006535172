<template>
  <section
    id="portada"
  >
    <div class="contenedor">
      <div class="elol">
        <div
          class="card"
        >
          <router-link :to="{ name: 'Pudin' }">
            <img
              :src="require(`@/assets/principal.jpg`)"
              loading="lazy"
            >
            <div
              class="card__head"
            >
              Pudin
            </div>
          </router-link>
        </div>
        <div
          class="card"
        >
          <router-link :to="{ name: 'Txangurro' }">
            <img
              :src="require(`@/assets/portada_txangurro.jpg`)"
              loading="lazy"
           >
            <div
              class="card__head"
            >
              TXANGURRO
            </div>
          </router-link>
        </div>
        <div
          class="card"
        >
          <router-link :to="{ name: 'Gourmet' }">
            <img
              :src="require(`@/assets/portada_gourmet.jpg`)"
              loading="lazy"
            >
            <div
              class="card__head"
            >
              Especialidades Gourmet
            </div>
          </router-link>
        </div>
        <div
          class="card"
        >
          <router-link :to="{ name: 'Pinchos' }">
            <img
              :src="require(`@/assets/portada_pincho.jpg`)"
              loading="lazy"
            >
            <div
              class="card__head"
            >
              Pinchos
            </div>
          </router-link>
        </div>
        <div
          class="card"
        >
          <router-link :to="{ name: 'Conservas' }">
            <img
              :src="require(`@/assets/portada_conservas.jpg`)"
              loading="lazy"
            >
            <div
              class="card__head"
            >
              Conservas
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  export default {
    name: 'SectionPortada',
  }
</script>

<style lang="scss">
  .contenedor{
    margin: 0;
    background:#fff;
    justify-content: center;
    align-items: center;
  }
  .elol{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transform: skew(0deg);
    .card{
      flex: 1;
      transition: all 0.70s ease-in-out;
      height: 90vmin;
      position: relative;
      cursor: pointer;
      .card__head{
        color: black;
        background: rgba(2, 150, 248, 0.75);
        padding: 0.5em;
        transform: rotate(-90deg);
        transform-origin: 0% 0%;
        transition: all 0.5s ease-in-out;
        min-width: 100%;
        text-align: center;
        position: absolute;
        bottom: 1px;
        left: 0;
        font-size: 1em;
        white-space: nowrap;
      }
      &:hover{
        flex-grow: 10;
        img {
          filter: grayscale(0);
        }
        .card__head {
          text-align: center;
          top: calc(100% - 2em);
          color: white;
          background: rgba(5, 11, 31, 0.5);
          font-size: 2em;
          transform: rotate(0deg) skew(-5deg);
        }
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 1s ease-in-out;
        filter: grayscale(100%);
      }
      &:not(:nth-child(5)) {
        margin-right: 1em;
      }
    }
  }
</style>
